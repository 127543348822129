import clsx from "clsx";

interface SpinnerProps {
	className?: string;
}

const Spinner = ({ className }: SpinnerProps) => {
	return (
		<div
			className={clsx(
				`border-2 rounded-full animate-spin
    dark:border-white/25 dark:border-t-white/75
    border-gray-200 border-t-gray-400`,
				className
			)}
		/>
	);
};

export default Spinner;
