import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

export default function SSRDocumentBodyPortal({
	children,
}: {
	children: ReactNode;
}) {
	const [body, setBody] = useState<HTMLElement | null>(null);
	useEffect(() => {
		setBody(document.body);
	}, []);

	return body && createPortal(children, body);
}
