import { useEffect } from "react";
import { FormState } from "react-hook-form";
import toast from "react-hot-toast";

export const useToastFormErrors = (formState?: FormState<any>) => {
	useEffect(
		() => {
			if (!formState?.errors) return;
			const activeErrors: string[] = [];
			for (const [key, error] of Object.entries(formState.errors))
				activeErrors.push(
					toast.error(`${key} ${error?.type}: ${error?.message}`, {
						duration: Infinity,
					})
				);

			return () => activeErrors.forEach(toast.dismiss);
		},
		// the errors object doesnt update, so we need to watch the formState object
		[formState]
	);
};
