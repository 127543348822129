import React, {
	createContext,
	useState,
	useContext,
	ReactNode,
	useCallback,
	useMemo,
} from "react";
import ConfirmationModal, { ConfirmationModalProps } from "./confirmationModal";

type NeededConfirmationProps = Omit<
	ConfirmationModalProps<any>,
	"isOpen" | "onConfirm" | "onClose"
> &
	Partial<Pick<ConfirmationModalProps<any>, "onClose">>;

type ConfirmationModalContextType = {
	confirmAndDo: <T>(
		onConfirm: () => T,
		confirmationProps: NeededConfirmationProps
	) => Promise<T>;
};

const ConfirmationModalContext = createContext<
	ConfirmationModalContextType | undefined
>(undefined);

export const useConfirmationModal = () => {
	const context = useContext(ConfirmationModalContext);
	if (!context) {
		throw new Error(
			"useConfirmationModal must be used within a ConfirmationModalProvider"
		);
	}
	return context;
};

export const ConfirmationModalProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [onConfirm, setOnConfirm] = useState<() => void>(() => () => {});
	const [restProps, setRestProps] = useState<NeededConfirmationProps>({
		header: "",
		confirmLabel: "",
		onClose: () => {},
	});

	const { onClose, ...restMinusCloseProps } = useMemo(
		() => restProps,
		[restProps]
	);

	const confirmAndDo = useCallback(
		<T,>(
			onConfirm: () => T,
			confirmationProps: NeededConfirmationProps
		): Promise<T> =>
			new Promise<T>((resolve) => {
				setOnConfirm(() => async () => {
					resolve(await onConfirm());
					setIsOpen(false);
				});
				setRestProps(confirmationProps);
				setIsOpen(true);
			}),
		[]
	);

	return (
		<ConfirmationModalContext.Provider value={{ confirmAndDo }}>
			{children}
			<ConfirmationModal
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false);
					onClose?.();
				}}
				onConfirm={onConfirm}
				{...restMinusCloseProps}
			/>
		</ConfirmationModalContext.Provider>
	);
};
