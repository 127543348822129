import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";
import Spinner from "../spinner";

type Props = Readonly<{
	children: React.ReactNode;
	className?: string;

	selected?: boolean;
	colored?: "blue" | "red";
	isProcessing?: boolean;
}> &
	Readonly<ButtonHTMLAttributes<HTMLButtonElement>>;

export default function Button(props: Props) {
	const {
		children,
		className,
		selected,
		colored,
		isProcessing,
		...buttonProps
	} = props;
	return (
		<div className="bg-black grid rounded-lg w-full select-none">
			<button
				{...buttonProps}
				className={clsx(
					"rounded-lg border sm:px-5 px-1 py-4 transition-all grid place-items-center hover:bg-opacity-100 disabled:border-opacity-80 disabled:bg-opacity-20 disabled:cursor-not-allowed",
					{
						"bg-neutral-700 border-neutral-700": !colored,
						"bg-sky-400 border-sky-400": colored === "blue",
						"bg-rose-500 border-rose-500": colored === "red",
						"bg-opacity-100": selected,
						"bg-opacity-40": !selected,
					},
					className
				)}
				disabled={buttonProps.disabled || isProcessing}
			>
				{isProcessing ? <Spinner className="w-6 h-6" /> : children}
			</button>
		</div>
	);
}
