"use client";

import { ConfirmationModalProvider } from "@/components/confirmation/provider";
import { ClerkProvider } from "@clerk/nextjs";
import { setUseWhatChange } from "@simbathesailor/use-what-changed";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { getRunnersInWarning } from "./dashboard/admin/globalUsage";
import { useRouter } from "next/navigation";
import ConfirmationModal from "@/components/confirmation/confirmationModal";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
	process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!
);

export default function Providers({
	children,
}: Readonly<{
	children: React.ReactNode;
}>) {
	setUseWhatChange(process.env.NODE_ENV === "development");
	const [threadWarningActive, setThreadWarningActive] = useState(0);
	useEffect(() => {
		const poll = async () => {
			setThreadWarningActive(await getRunnersInWarning());
		};
		const i = setInterval(poll, 300000); // 5 mins
		poll();

		return () => clearInterval(i);
	}, []);
	const router = useRouter();

	return (
		<ClerkProvider>
			<Elements
				stripe={stripePromise}
				options={{
					appearance: {
						theme: "night",
					},
				}}
			>
				<ConfirmationModalProvider>{children}</ConfirmationModalProvider>
				<ConfirmationModal
					isOpen={threadWarningActive > 0}
					onClose={() => setThreadWarningActive(0)}
					onConfirm={() => {
						router.push("/dashboard/threads");
						setThreadWarningActive(0);
					}}
					header={`You have ${threadWarningActive} runners in warning`}
					warning="Task runners will forcibly stop if you do not purchase the necessary threads."
					confirmLabel="Go to purchase"
					cancelLabel="Ignore"
				/>
			</Elements>
		</ClerkProvider>
	);
}
