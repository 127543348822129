import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import PopupFormModal, {
	FormInput,
	PopupFormModalProps,
} from "../popupFormModal";
import { FieldValues } from "react-hook-form";

export interface ConfirmationModalProps<T extends FieldValues> {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: PopupFormModalProps<T>["onSubmit"];
	header: string; // Added for the modal header
	warning?: string; // Added for the warning message
	confirmLabel: string; // Added for the confirm button label
	cancelLabel?: string; // Added for the cancel button label
	isDestructive?: boolean;

	children?: React.ReactNode;
}

const ConfirmationModal: React.FC<ConfirmationModalProps<any>> = ({
	isOpen,
	onClose,
	onConfirm,
	header,
	warning,
	confirmLabel,
	cancelLabel,
	isDestructive,
	children,
}) => {
	// if (!isOpen) return null;

	return (
		<PopupFormModal
			title={header}
			isOpen={isOpen}
			onClose={onClose}
			cancelLabel={cancelLabel}
			submitLabel={confirmLabel}
			onSubmit={onConfirm}
			isDestructive={isDestructive}
			inputs={[
				<FormInput key="i">
					<div className="space-y-2 text-white">
						{warning && (
							<div className="p-3 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 bg-opacity-80">
								<div className="flex">
									<div className="flex-shrink-0">
										<ExclamationTriangleIcon
											className="h-5 w-5"
											aria-hidden="true"
										/>
									</div>
									<div className="ml-3">
										<p className="text-sm opacity-100">{warning}</p>
									</div>
								</div>
							</div>
						)}
						{children}
					</div>
				</FormInput>,
			]}
		/>
	);
};

export default ConfirmationModal;
